<template>
    <p>{{ $t('text_about') }}</p>
    <a href="https://t.me/wolfinvestsite">
        <img src="/tg.png" alt="telegram" style="width: 128px; height: 128px"/>
    </a>
</template>
<style scoped>
p {
  font-family: 'Helvetica';
  font-size: 1.5em;
  margin: 0;
  margin-left: 10px;
}
</style>
