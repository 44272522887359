<template>
    <ListUsers/>
</template>
<script>
import ListUsers from "@/components/admin/ListUsers"

export default {
    components: {
        ListUsers,
    },
}
</script>
