export default {
    "currencies_list": "currencies",
    "username": "username",
    "password": "password",
    "log_in": "log in",
    "register": "register",
    "amount": "amount",
    "take_refill": "take refill",
    "change_password": "change password",
    "log_out": "log out",
    "price": "price",
    "balance": "balance",
    "total": "total",
    "during_all_time": "during all time",
    "enter_ticker": "if you don’t find the symbol you need, enter the ticker",
    "ticker": "ticker",
    "select": "select",
    "popular_symbols": "popular symbols",
    "symbol": "symbol",
    "buy": "buy",
    "sell": "sell",
    "symbol_not_found": "symbol not found",
    "old_password": "old password",
    "new_password": "new password",
    "confirm_password": "confirm password",
    "set_balance": "set balance",
    "set_password": "set password",
    "delete": "delete",
    "id": "id",
    "localeString": "en-UK",
    "price_buy": "purchase price",
    "price_sell": "selling price",
    "language": "language",
    "no_balance_changes": "you have no balance changes yet",
    "avaible_to_buy": "avaible",
    "avaible_to_sell": "avaible",
    "purchase_symbol": "purchase %name%",
    "sale_symbol": "sale %name%",
    "symbols_loading": "loading...",
    "company_logo": "%name% company logo",
    "currency_logo": "%name% logo",
    "purchase_currency": "purchase %name%",
    "sale_currency": "sale %name%",
    "vegan_companies": "vegan companies",
    "banks": "banks",
    "helper": "helper",
    "finish_tutorial": "finish tutorial",
    "reset_tutorial": "reset tutorial",
    "navbar": {
        "home": "Home",
        "symbols": "Symbols",
        "refills": "Refills",
        "settings": "Settings",
        "balance_history": "History",
        "about": "About",
        "admin": "Admin"
    },
    "helper": {
        "auth": "pass",
        "goto_refills": "pass",
        "select_refill": "pass",
        "goto_symbols": "pass",
        "symbols": "pass",
        "on_symbols": "pass",
    },
    "alerts": {
        "only_numbers": "can only enter numbers",
        "only_positive_numbers": "can only enter positive numbers",
        "username_taken": "this username already taken",
        "incorrect_login_data": "incorrect username or password",
        "not_enough_money_to_buy": "not enough money to buy",
        "symbol_purchased": "symbol purchased",
        "symbols_purchased": "symbols purchased",
        "not_enough_symbols_to_sell": "not enough symbols to sell",
        "symbol_sold": "symbol sold",
        "symbols_sold": "symbols sold",
        "password_minmatch": "password minmatch",
        "incorrect_password": "incorrect password",
        "balance_is_set": "balance is set",
        "password_is_set": "password is set",
        "user_is_deleted": "user is deleted",
        "currency_purchased": "currency purchased",
        "currencies_purchased": "currencies purchased",
        "currency_sold": "currency sold",
        "currencies_sold": "currencies sold",
    },
    "reason": {
        "1": "unknown",
        "2": "unknown",
        "3": "you bought %amount% worth of currency",
        "4": "you sold currency and get %amount%",
        "5": "you bought %amount% worth of %symbol_amount% %symbol_ticker%",
        "6": "you sold %symbol_amount% %symbol_ticker% and get %amount%",
        "7": "replenishment in the amount of %amount%",
    },
    "history_intervals": {
        "1d": "day",
        "1wk": "week",
        "1mo": "month",
        "3mo": "3 months",
        "1y": "year",
        "alltime": "during all time",
    },
    "title": {
        "root": "wolfinvest",
        "home": "Home",
        "symbols": "Symbols",
        "refills": "Refills",
        "settings": "Settings",
        "history": "History",
        "about": "About",
    },
    "currencies": {
        "EUR": "euro",
        "USD": "USA dollar",
        "UAH": "hryvnia",
        "CNY": "yuan",
        "RUB": "ruble",
        "HKD": "hong kong dollar",
        "JPY": "yen",
        "GBP": "pound sterling",
    },
    "text_about": "Are you interested in the idea of making money on stocks, but are you not yet ready to take the risk? Do you want to “just try” without investing any money or risking losing anything? Then you need our Simulator!\nHere you can invest virtual, free “money” in real stocks of real companies at their current price at the moment.\nBuy and sell stocks on our Simulator, and you will see your winnings and losses in real time - exactly the same amount as if you were playing on a real exchange!"
}
