<template>
    <input class="input">
</template>
<style scoped>
.input {
  background-color: var(--ui-input-background-color);
  color: var(--ui-input-color);
  padding: 14px;
  text-align: left;
  font-size: 1.6em;
  font-family: monospace;
  border: none;
  border-radius: 17px;
  margin: 5px;
}

input[type=number] { 
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
</style>
