export default {
    "currencies_list": "валюты",
    "username": "юзернейм",
    "password": "пароль",
    "log_in": "войти",
    "register": "зарегистрироваться",
    "amount": "количество",
    "take_refill": "пополнить",
    "change_password": "сменить пароль",
    "log_out": "выйти",
    "price": "цена",
    "balance": "счёт",
    "total": "итого",
    "during_all_time": "за всё время",
    "enter_ticker": "если не нашли нужную акцию то введите тикер",
    "ticker": "тикер",
    "select": "выбрать",
    "popular_symbols": "популярные акции",
    "symbol": "акция",
    "buy": "купить",
    "sell": "продать",
    "symbol_not_found": "акция не найдена",
    "old_password": "старый пароль",
    "new_password": "новый пароль",
    "confirm_password": "подтвердите пароль",
    "set_balance": "установить баланс",
    "set_password": "установить пароль",
    "delete": "удалить",
    "id": "id",
    "localeString": "ru-RU",
    "price_buy": "цена покупки",
    "price_sell": "цена продажи",
    "language": "язык",
    "no_balance_changes": "у вас пока нету изменений баланса",
    "avaible_to_buy": "доступно",
    "avaible_to_sell": "доступно",
    "purchase_symbol": "покупка %name%",
    "sale_symbol": "продажа %name%",
    "symbols_loading": "загружаем...",
    "company_logo": "логотип компании %name%",
    "currency_logo": "логотип %name%",
    "purchase_currency": "купить %name%",
    "sale_currency": "продать %name%",
    "vegan_companies": "веганские компании",
    "banks": "банки",
    "helper": "помощник",
    "finish_tutorial": "закончить обучение",
    "reset_tutorial": "вернуть обучение",
    "navbar": {
        "home": "Главная",
        "symbols": "Акции",
        "refills": "Пополнить",
        "settings": "Настройки",
        "balance_history": "История",
        "about": "О нас",
        "admin": "Админ"
    },
    "helper": {
        "auth": `Вас приветствует wolfinvest!\n
Перед вами полный аналог фондовой биржи с актуальными ценами.
Только бесплатный и конфиденциальный.
Соберите себе портфель акций и следите за своими успехами в реальном времени.\n
Для того чтобы начать придумайте имя для вашего счёта и придумайте пароль`,
        "goto_refills": `Ваш капитал пока пуст.
Для того чтобы пополнить его зайдите на страницу \"пополнить\"`,
        "select_refill": `Какой капитал вы хотели бы вложить в акции?
Напишите сумму в долларах, не сдерживайте свою фантазию.
Вы сможете пополнить свой счёт в любой момент.
Все пополнения будут записаны в историю баланса.`,
        "goto_symbols": `Отлично!
Теперь можно зайти на страницу \"акции\" чтобы наконец-то купить акции`,
        "symbols": `Здесь можно купить или продать акции и валюту.
Начните вводить название компании, чьи акции вы хотите купить (на английском языке).`,
        "on_symbols": "До свидания, желаю удачных инвестиций на wolfinvest",
    },
    "alerts": {
        "only_numbers": "можно вводить только числа",
        "only_positive_numbers": "можно ввести только положительное число",
        "username_taken": "этот юзернейм уже занят",
        "incorrect_login_data": "неверный юзернейм или пароль",
        "not_enough_money_to_buy": "недостаточно денег для покупки",
        "symbol_purchased": "акция куплена",
        "symbols_purchased": "акции купелны",
        "not_enough_symbols_to_sell": "недостаточно акций для продажи",
        "symbol_sold": "акция продана",
        "symbols_sold": "акции проданы",
        "password_minmatch": "пароли не совпадают",
        "incorrect_password": "неправильный пароль",
        "balance_is_set": "баланс установлен",
        "password_is_set": "пароль установлен",
        "user_is_deleted": "пользователь удалён",
        "currency_purchased": "валюта куплена",
        "currencies_purchased": "валюта куплена",
        "currency_sold": "валюта продана",
        "currencies_sold": "валюта продана",
    },
    "reason": {
        "1": "неизвестно",
        "2": "неизвестно",
        "3": "вы купили валюты на %amount%",
        "4": "вы продали валюту и получили %amount%",
        "5": "вы купили %symbol_amount% %symbol_ticker% на %amount%",
        "6": "вы продали %symbol_amount% %symbol_ticker% и получили %amount%",
        "7": "вы пополнили баланс на %amount%",
    },
    "history_intervals": {
        "1d": "день",
        "1wk": "неделя",
        "1mo": "месяц",
        "3mo": "3 месяца",
        "1y": "год",
        "alltime": "за всё время",
    },
    "title": {
        "root": "wolfinvest",
        "home": "Главная",
        "symbols": "Акции",
        "refills": "Пополнить",
        "settings": "Настройки",
        "history": "История баланса",
        "about": "О нас",
    },
    "currencies": {
        "EUR": "евро",
        "USD": "доллар США",
        "UAH": "гривна",
        "CNY": "юань",
        "RUB": "рубль",
        "HKD": "гонконгский доллар",
        "JPY": "иена",
        "GBP": "фунты стерлингов",
    },
    "text_about": "Вас заинтересовала идея зарабатывать на акциях, но вы пока не готовы к риску? Хотите \"просто попробовать\", не вкладывая никаких денег и не рискуя ничего потерять? Тогда вам нужен наш Сайт!\nЗдесь вы сможете вкладывать виртуальные, бесплатные \"деньги\" в самые настоящие акции реальных компаний по их актуальной цене на данную минуту. Покупайте и продавайте акции на нашем Сайте, и вы увидите в реальном времени свои выигрыши и проигрыши — ровно в том размере, как если бы вы играли на настоящей бирже!"
}
